
import './App.css';
//import animate from "./animate";
import logo from "./images/LogoComp120.png";

import { useState } from "react"
const { Configuration, OpenAIApi } = require("openai");



const App = () => {
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY
    //"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",
  });
  //process.env.REACT_APP_OPENAI_API_KEY);
  // const testValor = () => {
  //   return "Valor asignado";
  // };

  function asignaPrompt(valorPasado) {
    const servIT = " List of IT Services";
    const devOps = "List FullStack automate software";

    if (valorPasado != null)
      if (valorPasado == "Automate")
        setPrompt(devOps);
      else
        setPrompt("List of " + valorPasado);
    else
      setPrompt(servIT);
  }

  var i = 0;
  function Renglones() {
    
     var separa = apiResponse.replace(/[0-9](.)/g, '\n');
     
    
    const separaArra = separa.split('\n').filter(n => n)
    const arra2 = [];
    console.log(">>> "+separaArra[separaArra.length - 2]);
    const ii = separaArra.length;
    for (i = 0; i <= ii; i++) {
      
      arra2.push(<li ><a href='#'>{separaArra[i]}</a></li>);
    }
    return (
      <ul className='resources'>{arra2}</ul>
    );
    
  }

  function Render() {

    var i = 0;
    const [prompt, setPrompt] = useState('Agenda');
    const arra = ["Automate", "Cloud", "Services", "On-Premise", "On-Premise Hardware", "manufactures of computers components"];
    // const Asignavalor = (i) => {
    //     setPrompt = useState(arra[i]);
    // }
    const arra1 = [];
    const ii = arra.length;
    for (i = 0; i < ii; i++) {
      const pasoParametro = arra[i];
      arra1.push(<button id={arra[i]} className="botons" value={prompt} onClick={() => asignaPrompt(pasoParametro)}> {arra[i]}</button>);
    }
    return (
      <nav className="button1">{arra1}</nav>
    );
  }

  const openai = new OpenAIApi(configuration);
  var [prompt, setPrompt] = useState("Agenda");
  const [apiResponse, setApiResponse] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: prompt,
        temperature: 0.5,
        max_tokens: 4000,
      });
      //console.log("response", result.data.choices[0].text);
      setApiResponse(result.data.choices[0].text);
    } catch (e) {
      //console.log(e);
      setApiResponse("Something is going wrong, Please try again.");
    }
    setLoading(false);
  };


  return (
    <>
      <div className='h11'>
        <div className='boxlogo'><img src={logo} ></img>
        </div>
        <div className='textlogo'><h2>Bitegra Multimedios</h2></div>
        <div className='slogan'>Your Business Intelligence with <strong>AI</strong></div>
        <hr/>
        
        <h2 >What do you looking for?</h2>

      </div>
      <div className='prompt' >

        <form onSubmit={handleSubmit}>

          {Render()}

          <div className='button'>
            <button
              disabled={loading || prompt.length === 0}
              type="submit"
            >
              {loading ? "Looking for solutions..." : "How we helped you?"}
            </button>
          </div>


        </form>
      </div>
      {apiResponse && (
        <div className='button1'
        // style={{
        //   display: "flex",
        //   justifyContent: "center",

        // }}
        >
          <pre>
            <strong>{prompt}:</strong>
            <div className='respuesta'>{apiResponse}</div>
            <div >{Renglones()}</div>
          </pre>
        </div>

      )}
      <div className='footer'>
        <p>We could help you that the topics above</p>
      </div>
    </>
  );
};


export default App;